/** @format */

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import Base from "./components/layouts/Base";
import Home from "./pages/Home";
import Hero from "./components/elements/Hero";
import Leadership from "./components/elements/Leadership";
import Players from "./components/elements/Players";
import PlayerDetails from "./components/elements/PlayerDetails";
import Marketplace from "./components/elements/Marketplace";
import { ToastContainer } from "react-toastify";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";
import ReactGA from 'react-ga';
ReactGA.initialize('G-47PX9R0B3B');
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  const chains = [mainnet];
  const projectId = "1c200fb2c2e182bbda9170cd52450702";

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <BrowserRouter>
          <Base>
            <Routes>
              <Route index path='/' element={<Home />} />
              <Route index path='/leadership' element={<Leadership />} />
              <Route index path='/players' element={<Players />} />
              <Route index path='/dashboard' element={<PlayerDetails />} />
              <Route index path='/reward-pool' element={<Marketplace />} />
            </Routes>
          </Base>
        </BrowserRouter>
        <ToastContainer
          position='top-center'
          toastClassName='fex-toast'
          className='fex-toast-container'
          theme='light'
        />
        <div className='z-[9999] z-2  postion-relative'>
          <Web3Modal
            projectId={projectId}
            ethereumClient={ethereumClient}
            defaultChain={1}
          />
        </div>{" "}
      </WagmiConfig>
    </>
  );
}

export default App;

import React from "react";

function Players() {
  return (
    <div className="container setMEdia p-3 leadership mt-4">
     
    
      <div className="detail-row">
      <div className="row">
        <div className="col-md-12">
          <div className="player-bg">
            <div className="row">
              <div className="col-md-2 text-center">
                <img src={"/assets/Frame.svg"} alt="" />
              </div>
              <div className="col-md-1">
                <img src={"/assets/1.svg"} alt="" />
              </div>
              <div className="col-md-3">
                <h2>TOP PLAYER</h2>
                <span>8BMwvX4C......nTxbwPYxtf</span>
              </div>
              <div className="col-md-2">
                <h2>TOTAL ASSET VALUE</h2>
                <h3>49,485,048</h3>
                <h4>USDC</h4>
              </div>
              <div className="col-md-2">
                <h2>ORGANIZATIONS</h2>
                <p>None</p>
              </div>
              <div className="col-md-2">
                <h2>FACTION</h2>
                <h3>MUD</h3>
              </div>
            </div>
          </div>
        </div>



        <div className="col-md-12 mt-4">
          <div className="player-bg">
            <div className="row">
              <div className="col-md-2 text-center">
                <img src={"/assets/Frame.svg"} alt="" />
              </div>
              <div className="col-md-1">
                <img src={"/assets/4.svg"} alt="" />
              </div>
              <div className="col-md-3">
                <h2>TOP PLAYER</h2>
                <span>8BMwvX4C......nTxbwPYxtf</span>
              </div>
              <div className="col-md-2">
                <h2>TOTAL ASSET VALUE</h2>
                <h3>49,485,048</h3>
                <h4>USDC</h4>
              </div>
              <div className="col-md-2">
                <h2>ORGANIZATIONS</h2>
                <p>None</p>
              </div>
              <div className="col-md-2">
                <h2>FACTION</h2>
                <h3>MUD</h3>
              </div>
            </div>
          </div>
        </div>



        <div className="col-md-12 mt-4">
          <div className="player-bg">
            <div className="row">
              <div className="col-md-2 text-center">
                <img src={"/assets/4.svg"} alt="" />
              </div>
              <div className="col-md-1">
                <img src={"/assets/2.svg"} alt="" />
              </div>
              <div className="col-md-3">
                <h2>TOP PLAYER</h2>
                <span>8BMwvX4C......nTxbwPYxtf</span>
              </div>
              <div className="col-md-2">
                <h2>TOTAL ASSET VALUE</h2>
                <h3>49,485,048</h3>
                <h4>USDC</h4>
              </div>
              <div className="col-md-2">
                <h2>ORGANIZATIONS</h2>
                <p>None</p>
              </div>
              <div className="col-md-2">
                <h2>FACTION</h2>
                <h3>MUD</h3>
              </div>
            </div>
          </div>
        </div>
 

        <div className="col-md-12 mt-4">
          <div className="player-bg">
            <div className="row">
              <div className="col-md-2 text-center">
                <img src={"/assets/Frame.svg"} alt="" />
              </div>
              <div className="col-md-1">
                <img src={"/assets/3.svg"} alt="" />
              </div>
              <div className="col-md-3">
                <h2>TOP PLAYER</h2>
                <span>8BMwvX4C......nTxbwPYxtf</span>
              </div>
              <div className="col-md-2">
                <h2>TOTAL ASSET VALUE</h2>
                <h3>49,485,048</h3>
                <h4>USDC</h4>
              </div>
              <div className="col-md-2">
                <h2>ORGANIZATIONS</h2>
                <p>None</p>
              </div>
              <div className="col-md-2">
                <h2>FACTION</h2>
                <h3>MUD</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <div className="player-bg">
            <div className="row">
              <div className="col-md-2 text-center">
                <img src={"/assets/Frame.svg"} alt="" />
              </div>
              <div className="col-md-1">
                <img src={"/assets/3.svg"} alt="" />
              </div>
              <div className="col-md-3">
                <h2>TOP PLAYER</h2>
                <span>8BMwvX4C......nTxbwPYxtf</span>
              </div>
              <div className="col-md-2">
                <h2>TOTAL ASSET VALUE</h2>
                <h3>49,485,048</h3>
                <h4>USDC</h4>
              </div>
              <div className="col-md-2">
                <h2>ORGANIZATIONS</h2>
                <p>None</p>
              </div>
              <div className="col-md-2">
                <h2>FACTION</h2>
                <h3>MUD</h3>
              </div>
            </div>
          </div>
        </div>
      </div>  
      </div>

    </div>
  );
}

export default Players;

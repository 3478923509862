/** @format */

import React from "react";

function Marketplace() {
  const data = [
    {
      head: "Generated from AI BOT",
      lead: "0.00 ETH",
      img: "/assets/Frame (4).svg",
      para: "0.00%",
      button: "Daily",
      btn: "view item",
      color: "#FFCC00",
    },
    {
      head: "Generated from AI BOT",
      lead: "0.00 ETH",
      img: "/assets/Frame (4).svg",
      para: "0.00%",
      button: "Weekly",
      btn: "view item",
      color: "#06C270",
    },
    {
      head: "Generated from AI BOT",
      lead: "0.00 ETH",
      img: "/assets/Frame (4).svg",
      para: "0.00%",
      button: "Monthly",
      btn: "view item",
      color: "#FF6262",
    },
    {
      head: "Generated from AI BOT",
      lead: "0.00 ETH",
      img: "/assets/Frame (4).svg",
      para: "0.00%",
      button: "Since Launch",
      btn: "view item",
      color: "#0063F7",
    },
  ];

  const data1 = [
    {
      head: "Generated from Tax",
      lead: "0.00 ETH",
      img: "/assets/Frame (4).svg",
      para: "0.00%",
      button: "Daily",
      btn: "view item",
      color: "#FFCC00",
    },
    {
      head: "Generated from Tax",
      lead: "0.00 ETH",
      img: "/assets/Frame (4).svg",
      para: "0.00%",
      button: "Weekly",
      btn: "view item",
      color: "#06C270",
    },
    {
      head: "Generated from Tax",
      lead: "0.00 ETH",
      img: "/assets/Frame (4).svg",
      para: "0.00%",
      button: "Monthly",
      btn: "view item",
      color: "#FF6262",
    },
    {
      head: "Generated from Tax",
      lead: "0.00 ETH",
      img: "/assets/Frame (4).svg",
      para: "0.00%",
      button: "Since Launch",
      btn: "view item",
      color: "#0063F7",
    },
  ];
  return (
    <div className='container p-3 mt-4 setMEdia leadership'>
      <div className='row'>
        <div className='col-md-12 heading'>
          <h1 className='head'>Reward Pool Live Feed</h1>
          <hr />
          <div className='detail-row row'>
            <div className='col-md-3'>
              <div className='card market-card mt-2'>
                <div className='card-body'>
                  <h1>0.00 ETH</h1>
                  <p>Generated Today</p>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='card market-card mt-2'>
                <div className='card-body'>
                  <h1>0.00 ETH</h1>
                  <p>Generated this week</p>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='card market-card mt-2'>
                <div className='card-body'>
                  <h1>0.00 ETH</h1>
                  <p>Generated this month</p>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='card market-card mt-2'>
                <div className='card-body'>
                  <h1>0.00 ETH</h1>
                  <p>Generated since launch</p>
                </div>
              </div>
            </div>
            <div className='row pt-3'>
            <p className='text-light fst-italic '>From AI BOT</p>
              {data.map((v, i) => {
                return (
                  <>
                    <div className='col-md-3' key={i}>
                      <div className='card market-card mt-4'>
                        <div className='card-body'>
                          <p>{v.head}</p>
                          <h1>{v.lead}</h1>
                          <img src={v.img} alt='' />
                          <p>{v.para}</p>
                          <button className='btn-1' style={{ color: v.color }}>
                            {v.button}
                          </button>{" "}
                          <br />
                          <br />
                          {/* <button className='btn-2'>{v.btn}</button> */}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className='row mt-4'>
              <div className='col-md-12 heading'>
                <hr />
                <p className='text-light fst-italic '>From Tax</p>

                <div className='row'>
                  {data1.map((v, i) => {
                    return (
                      <>
                        <div className='col-md-3' key={i}>
                          <div className='card market-card mt-4'>
                            <div className='card-body'>
                              <p>{v.head}</p>
                              <h1>{v.lead}</h1>
                              <img src={v.img} alt='' />
                              <p>{v.para}</p>
                              <button
                                className='btn-1'
                                style={{ color: v.color }}>
                                {v.button}
                              </button>{" "}
                              <br />
                              {/* <button className='btn-2'>{v.btn}</button> */}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Marketplace;

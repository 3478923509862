/** @format */

import React, { useState } from "react";
import numbro from "numbro";
import { utils } from "ethers";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useSwitchNetwork, useEnsName, useNetwork } from "wagmi";
import Web3 from "web3";

function PlayerDetails() {
  const web3 = new Web3(
    Web3.givenProvider ||
      "https://mainnet.infura.io/v3/d5c09c17fb5446728d3818bc4afeca69",
  );
  const [balance, setBalance] = useState(null);
  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { open, close } = useWeb3Modal();
  const { chain } = useNetwork();
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork();

  function truncate(str, n) {
    return str.length > n ? str.slice(0, 4) + "..." + str.slice(-4) : str;
  }
  const getFullDisplayBalance = (balance, format = {}) => {
    return numbro(utils.formatEther(balance)).format(Object.assign({}, format));
  };

  const bal = () => {
    if (address) {
      web3.eth.getBalance(address).then(balance => {
        setBalance(getFullDisplayBalance(balance));
      });
    }
  };

  bal();
  return (
    <div className='container p-3 mt-4 mb-3 setMEdia leadership'>
      <div className='row'>
        <div className='col-md-12'>
          {/* <div className="back-to-leader mb-4">
            <Link to="/leadership"><BsArrowLeft/>  Back to leaderboards</Link>
            </div> */}
          <div className='row'>
            <div className='col-md-7 playerdetails'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='player-photo'>
                    <img src={"/assets/Ellipse 11.svg"} alt='' />
                  </div>
                </div>
                <div className='col-md-6 '>
                  <p className='mb-0'>Wallet Address</p>
                  <h1>
                    {" "}
                    {isConnected
                      ? truncate(
                          `${ensName ? `${ensName} (${address})` : address}`,
                          8,
                        )
                      : "Not connect"}
                  </h1>

                  <p className='mb-0 pt-3'>Balance</p>
                  <h1> {isConnected ?  balance + " ETH" : "0.00 ETH"}</h1>

                  <p className='mb-0 pt-3'>$0xFutures Token</p>
                  <h1 style={{ color: "#545D69" }}>0.00</h1>
                </div>
              </div>
              <hr />

              <div className='row'>
                <div className='col-md-4'>
                  <p>Earn Reward</p>
                  <p>- - -</p>
                </div>

                <div className='col-md-4'>
                  <p>Current Price</p>
                  <h1 style={{ fontSize: "18px" }}>$0.00</h1>
                </div>

                <div className='col-md-4'>
                  <p>Chain</p>
                  <h1 style={{ fontSize: "18px" }}>Ethereum</h1>
                </div>
              </div>

              <div className='row mt-4'>
                <div className='col-md-4'>
                  <p className='mb-0'>ETH Liquidity Value:</p>
                  <h1 style={{ fontSize: "18px" }}>$0.00</h1>
                  {/* <span style={{ color: "#DA4614" }}>USDC</span> */}
                </div>
                <div className='col-md-4'>
                  <p className='mb-0'>Pool Value:</p>
                  <h1 style={{ fontSize: "18px" }}>- - - </h1>
                </div>
                {/* <div className='col-md-4'>
                  <p className='mb-0'>COUNTRY OF ORIGIN</p>
                  <p>Undisclosed</p>
                </div> */}
              </div>
            </div>

            <div className='col-md-5'>
              <div className=' details-rt'>
                <h1>Claim Your ETH</h1>
                <div className='row p-4'>
                  <div className='col-md-6'>
                    <div className='details-rt-img'>
                      <img src={"/assets/12.svg"} alt='' />
                    </div>
                  </div>
                  <div className='col-md-6 m-auto '>
                    <p>Value</p>
                    <h2>0.000%</h2>
                    <br />
                    <div className='lock-bg '>
                      <h2>Your % of claim</h2>
                    </div>
                  </div>
                  {/* <div className="col-md-2">
                        <div className="lock-bg">
                            <HiLockClosed color="white" size={25}/>
                        </div>
                    </div> */}
                </div>
                <hr />

                <div className='row p-4'>
                  <div className='col-md-6'>
                    <div className='details-rt-img'>
                      <img src={"/assets/12.svg"} alt='' />
                    </div>
                  </div>
                  <div className='col-md-6 m-auto '>
                    <p>Value</p>
                    <h2>0.000 ETH</h2>
                    <br />
                    <div className='lock-bg '>
                      <h2>Your share of ETH</h2>
                    </div>
                  </div>
                  {/* <div className="col-md-2">
                        <div className="lock-bg">
                            <HiLockClosed color="white" size={25}/>
                        </div>
                    </div> */}
                </div>

                <div className='row p-4'>
                  <div className='col-md-6'>
                    <div className='details-rt-img'>
                      <img src={"/assets/12.svg"} alt='' />
                    </div>
                  </div>
                  <div className='col-md-6 m-auto '>
                    <p>Value</p>
                    <h2>0.000 ETH</h2>
                    <br />
                    <div className='lock-bg '>
                      <h2>Investors total DIVs</h2>
                    </div>
                  </div>
                  {/* <div className="col-md-2">
                        <div className="lock-bg">
                            <HiLockClosed color="white" size={25}/>
                        </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerDetails;

import React from 'react'
import Sidebar from '../elements/Sidebar'

function Base(props) {
  return (
    <>
    <Sidebar/>
    {props.children}
    </>
  )
}

export default Base
/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { FaUserCircle, FaGlobeAsia } from "react-icons/fa";
import { GiWhiteBook, GiTwoCoins } from "react-icons/gi";
import { MdDashboard } from "react-icons/md";
import Web3 from "web3";
import numbro from "numbro";
import { utils } from "ethers";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useSwitchNetwork, useEnsName, useNetwork } from "wagmi";
function Sidebar() {
  const web3 = new Web3(
    Web3.givenProvider ||
      "https://mainnet.infura.io/v3/d5c09c17fb5446728d3818bc4afeca69",
  );
  const [balance, setBalance] = useState(null);
  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { open, close } = useWeb3Modal();
  const { chain } = useNetwork();
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork();

  function truncate(str, n) {
    return str.length > n ? str.slice(0, 4) + "..." + str.slice(-4) : str;
  }
  const getFullDisplayBalance = (balance, format = {}) => {
    return numbro(utils.formatEther(balance)).format(Object.assign({}, format));
  };

  const bal = () => {
    if (address) {
      web3.eth.getBalance(address).then(balance => {
        setBalance(getFullDisplayBalance(balance));
      });
    }
  };

  bal();
  return (
    <div className='container sidebar-container'>
      <div className='row'></div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='sidebar d-flex flex-column justify-content-between'>
            <img src='assets/logo.png' width={200} alt='' />
            <div>
              <Link to='/'>
                <AiFillHome /> &nbsp;Home
              </Link>
              <Link to='/dashboard'>
                <MdDashboard /> &nbsp;Dashboard
              </Link>
              <Link to='/reward-pool'>
                <GiTwoCoins /> &nbsp;Live Reward Pools
              </Link>
              <Link to='/leadership'>
                <FaUserCircle /> &nbsp;Leadership
              </Link>
              {/* <Link to='/players'>
                <SiGoogleanalytics /> &nbsp;Inventory
              </Link>
            */}
              <a
                href='https://0xfutures.gitbook.io/0xfutures.trade'
                target='_blank'
                rel='noreferrer'>
                <GiWhiteBook /> &nbsp;Whitepaper
              </a>
              <a
                href='https://0xfutures.trade/'
                target='_blank'
                rel='noreferrer'>
                <FaGlobeAsia /> &nbsp;Website
              </a>
            </div>

            <div>
              {isConnected && chain?.id !== 1 ? (
                chains.map(x => (
                  <button
                    disabled={!switchNetwork || x.id === chain?.id}
                    key={x.id}
                    onClick={() => switchNetwork?.(x.id)}>
                    Switch {x.name}
                    {isLoading && pendingChainId === x.id && " (switching)"}
                  </button>
                ))
              ) : (
                <button onClick={open}>
                  {isConnected
                    ? truncate(
                        `${ensName ? `${ensName} (${address})` : address}`,
                        8,
                      )
                    : "Connect Wallet"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;

/** @format */

import React from "react";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useSwitchNetwork, useNetwork } from "wagmi";
import { Link } from "react-router-dom";
function Hero() {
  const {  isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { chains, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork();
    
  return (
    <div className='container-fluid hero-bg pt-4'>
      <div className='container setMEdia'>
        <div className='row'>
          <div className='col-md-12'>
            <video
              playsInline
              autoPlay
              muted
              loop
              id='bgvid'
              className='bg-video'>
              <source src={"/assets/file.mp4"} type='video/mp4' />
            </video>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12 mt-5 pt-5 d-flex justify-content-end '>
            <div className='hero-box '>
              <h1>Gain Your Competitive Edge</h1>
              <hr />
              <p>
                Formulate Your Strategy, Make your Claims, Overcome youre
                Opponents.
              </p>
              {isConnected && chain?.id !== 1 ? (
                chains.map(x => (
                  <button
                    disabled={!switchNetwork || x.id === chain?.id}
                    key={x.id}
                    onClick={() => switchNetwork?.(x.id)}>
                    Switch {x.name}
                    {isLoading && pendingChainId === x.id && " (switching)"}
                  </button>
                ))
              ) : (
                <>
                  {isConnected ? (
                    <Link to='/dashboard'><button>Dashboard</button></Link>
                  ) : (
                    <button onClick={open}>Connect Wallet</button>
                  )}
                </>
              )}
              <a href='#!' className='m-auto'>
                Terms of Service
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;

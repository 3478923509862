/** @format */

import React from "react";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useSwitchNetwork, useEnsName, useNetwork } from "wagmi";

function Leadership() {
  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  function truncate(str, n) {
    return str.length > n ? str.slice(0, 4) + "..." + str.slice(-4) : str;
  }
  return (
    <div className='container setMEdia mt-4 mb-3 leadership p-3'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='leader-box'>
            <div className='row'>
              <div className='col-md-3 m-auto text-center'>
                <img src={"/assets/Frame (1).svg"} alt='' />
              </div>
              <div className='col-md-2'>
                <div className='d-flex align-items-start'>
                  <img src={"/assets/logo-cr.png"} className='w-50' alt='' />
                  <div className='m-auto'>
                    <h1 className='mb-0 fs-5'>0xFutures</h1>
                    <p className='mx-2'>Pool</p>
                  </div>
                </div>

                <div className='mt-5'>
                  <p className='mb-0'>TOTAL STAKE VALUE</p>
                  <h2 className='mb-0'>0.00</h2>
                  <span>$0xFutures</span>
                </div>
              </div>
              <div className='col-md-3'>
                <h3>DPY</h3>
                <p>- - - %</p>

                <h3>APY</h3>
                <p>- - - %</p>

                <h3>MY TOTAL EARNED REWARDS</h3>
                <p>0.00</p>
              </div>

              <div className='col-md-3'>
                <h3>Wallet Address</h3>
                <p style={{ color: "#0063F7" }}>
                  {" "}
                  {isConnected
                    ? truncate(
                        `${ensName ? `${ensName} (${address})` : address}`,
                        8,
                      )
                    : "not connect"}
                </p>
                <h3>MY STAKED BALANCE</h3>
                <h2>0.00</h2>
                <h3>MY PENDING REWARDS</h3>
                <h2>0.00</h2>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-12 mt-3'>
          <div className='leader-box'>
            <div className='row'>
              <div className='col-md-4 m-auto text-center'>
                <img src={"/assets/Frame (2).svg"} alt='' />
              </div>
              <div className='col-md-2'>
                <div className='d-flex align-items-start'>
                  <img src={"/assets/logo-cr.png"} className='w-50' alt='' />
                  <div>
                    <h1 className='mb-0 fs-3'>ETH</h1>
                    <p className='mx-2'>Pool</p>
                  </div>
                </div>

                <div className='mt-5'>
                  <p className='mb-0'>TOTAL STAKE VALUE</p>
                  <h2 className='mb-0'>0.00</h2>
                  <span>$0xFutures</span>
                </div>
              </div>
              <div className='col-md-3'>
                <h3>DPY</h3>
                <p>- - - %</p>

                <h3>APY</h3>
                <p>- - - %</p>

                <h3>MY TOTAL EARNED REWARDS</h3>
                <p>0.00</p>
              </div>

              <div className='col-md-3'>
                <h3>Wallet Address</h3>
                <p style={{ color: "#0063F7" }}>
                  {" "}
                  {isConnected
                    ? truncate(
                        `${ensName ? `${ensName} (${address})` : address}`,
                        8,
                      )
                    : "not connect"}
                </p>
                <h3>MY STAKED BALANCE</h3>
                <h2>0.00</h2>
                <h3>MY PENDING REWARDS</h3>
                <h2>0.00</h2>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='col-md-12 mt-3'>
          <div className='leader-box'>
            <div className='row'>
              <div className='col-md-4 m-auto text-center'>
                <img src={"/assets/Frame (3).svg"} alt='' />
              </div>
              <div className='col-md-2'>
                <div className='d-flex align-items-start'>
                  <img src={"/assets/logo-cr.png"} className="w-50" alt='' />
                  <div>
                    <h1 className='mb-0 fs-3'>Space</h1>
                    <p className='mx-2'>Pool</p>
                  </div>
                </div>

                <div className='mt-5'>
                  <p className='mb-0'>TOTAL STAKE VALUE</p>
                  <h2 className='mb-0'>0.00</h2>
                  <span>$space</span>

                </div>
              </div>
              <div className='col-md-3'>
                <h3>DPY</h3>
                <p>- - - %</p>

                <h3>APY</h3>
                <p>- - - %</p>


                <h3>MY TOTAL EARNED REWARDS</h3>
                <p>0.00</p>
              </div>

              <div className='col-md-3'>
                <h3>TOP PLAYER</h3>
                <p style={{ color: "#0063F7" }}>0x00...000</p>
                <h3>MY STAKED BALANCE</h3>
                <h2>0.00</h2>
                <h3>MY PENDING REWARDS</h3>
                <h2>0.00</h2>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Leadership;
